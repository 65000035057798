import React from "react"

export const PlayButtonIcon = props => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 160 160"
    {...props}
  >
    <path d="M96 80 72 93.856V66.144L96 80Z" fill="#fff" />
  </svg>
)
