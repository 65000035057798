import styled from "@emotion/styled"
import { graphql } from "gatsby"
import React from "react"
import Card from "../components/card"
import { CardFullWidth } from "../components/cards/cardFullWidth"
import { ContentfulImage } from "../components/ContentfulImage"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { SuccessStoriesPreview } from "../components/successStoriesPreview"
import Typography, {
  TypographyMarkdown,
  typographyStyles,
} from "../components/typography"
import { CardVideoWidget } from "../components/widgets/CardVideoWidget"

import { QuoteWidget } from "../components/widgets/QuoteWidget"
import { SideBySideWidget } from "../components/widgets/SideBySideWidget"
import { StatsWidget } from "../components/widgets/StatsWidget"
import colors from "../styles/colors"

import { SuccessStory as SuccessStoryProps } from "../types/widget"
import { trackEvent } from "../utils/trackEvent"

const HeroCard = styled(Card)`
  position: relative;
  display: flex;
  flex-direction: row;
  height: 750px;

  @media (max-width: 768px) {
    height: initial;
    flex-direction: column;
  }
`

const HeroBackground = styled.div`
  position: absolute;
  width: 700px;
  height: 700px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 815px;
  background-color: ${colors.lightgrey};

  @media (max-width: 768px) {
    width: 500px;
    height: 500px;
  }
`
const HeroText = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  z-index: 1;

  @media (min-width: 769px) {
    min-width: 50%;
    max-width: 630px;
  }
`

const ImageWrapper = styled.div<{ width?: number }>`
  max-width: ${p => (p.width ? `${p.width}px` : "initial")};
`

const HeroImg = styled(ContentfulImage)`
  align-self: flex-end;
  position: relative;
  flex: 1;

  @media (max-width: 768px) {
    width: 100%;
  }
`

const ExcerptMarkdown = styled(TypographyMarkdown)`
  margin-top: 1rem;
  p {
    ${typographyStyles.h2}
  }
`

interface ContentfulSuccessStory {
  contentfulSuccessStory: SuccessStoryProps
}

interface LandingPageProps {
  data: ContentfulSuccessStory
  pageContext: string
}

const SuccessStory = ({ data }: LandingPageProps): JSX.Element => {
  const {
    companyName,
    excerpt,
    productIntroPicture,
    statsWidget,
    video,
    challange,
    quote,
    solution,
    keyFeatures,
  } = data.contentfulSuccessStory

  const title = `${companyName} + Mavenoid`

  let newImageWidth: number | undefined = undefined
  if (productIntroPicture) {
    const { width, height } = productIntroPicture.gatsbyImageData
    if (width / height < 1) {
      newImageWidth = (width / height) * width
    }
  }

  return (
    <Layout newsletter={null}>
      <SEO title={title} />
      <HeroCard variant="white" style={{ color: colors.darkgrey }}>
        <HeroBackground />
        <HeroText>
          <Typography variant="h5" as="h1" style={{ color: colors.primary }}>
            {companyName} + Mavenoid
          </Typography>
          <ExcerptMarkdown
            dangerouslySetInnerHTML={{
              __html: excerpt.childMarkdownRemark.html,
            }}
          />
        </HeroText>
        {productIntroPicture && newImageWidth ? (
          <ImageWrapper width={newImageWidth}>
            <HeroImg image={productIntroPicture} />
          </ImageWrapper>
        ) : (
          <HeroImg image={productIntroPicture} />
        )}
      </HeroCard>
      {statsWidget && <StatsWidget widget={statsWidget} />}
      {video && <CardVideoWidget widget={video} />}
      {challange && (
        <SideBySideWidget
          widget={challange}
          preamble="challenge"
          overrideTitleColor="primary"
          overrideMarkdownTypography="bodySmall"
        />
      )}
      {quote && <QuoteWidget widget={quote} />}
      {solution && (
        <SideBySideWidget
          widget={solution}
          preamble="solution"
          overrideTitleColor="primary"
          overrideMarkdownTypography="bodySmall"
        />
      )}
      {keyFeatures && (
        <SideBySideWidget
          widget={keyFeatures}
          overrideMarkdownTypography="bodySmall"
        />
      )}
      <CardFullWidth
        variant="primary"
        title="Experience support that really works"
        description={
          <>
            Unlike generic support tools that focus on deflection, Mavenoid is
            all about solutions.
            <br />
            <br />
            Schedule a demo and experience how Mavenoid meets customers with the
            right level of support to take them seamlessly from request to
            solution.
          </>
        }
        trackTryNow={() => {
          trackEvent({
            category: "sign_up_redirect",
            label: "self service try free try now",
            siteSection: "jabra_success_story_try_now_banner",
          })
        }}
      />

      <SuccessStoriesPreview />
    </Layout>
  )
}

export default SuccessStory

export const query = graphql`
  query SuccessStory($contentfulId: String!) {
    contentfulSuccessStory(contentful_id: { eq: $contentfulId }) {
      companyName
      excerpt {
        childMarkdownRemark {
          html
        }
      }
      productIntroPicture {
        ...gatsbyImageTracedSVG
      }
      statsWidget {
        ...StatsWidget
      }
      video {
        ...CardVideoWidget
      }
      quote {
        ...QuoteWidget
      }
      challange {
        ...SideBySideWidget
      }
      solution {
        ...SideBySideWidget
      }
      keyFeatures {
        ...SideBySideWidget
      }
    }
  }
`
