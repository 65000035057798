import styled from "@emotion/styled"
import React from "react"
import { MobileMediaQuery } from "../../styles/constants"
import { HoverButton } from "../button"
import Card from "../card"
import { PlayButtonIcon } from "../icons/playButtonIcon"
import { graphql } from "gatsby"
import { IWidgetProps, WidgetVideo } from "../../types/widget"
import { ContentfulImage } from "../ContentfulImage"

const Wrapper = styled(Card)`
  position: relative;
  cursor: pointer;

  ${MobileMediaQuery} {
    padding: 0;
  }
`

const VideoRelativeWrapper = styled.div`
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
`

const AbsoluteWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding-bottom: 56.25%;
`

const PlayButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const PlaceholderWrapper = styled.div<{ isVisible: boolean }>`
  opacity: ${p => (p.isVisible ? 1 : 0)};
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`
const BUTTON_RADIUS = "120px"
const BUTTON_RADIUS_MOBILE = "80px"
const PlayButton = styled(HoverButton)<{ isVisible: boolean }>`
  display: flex;
  width: ${BUTTON_RADIUS};
  min-width: ${BUTTON_RADIUS};
  height: ${BUTTON_RADIUS};
  min-height: ${BUTTON_RADIUS};
  opacity: ${p => (p.isVisible ? 1 : 0)};
  border-radius: 100px;
  padding: 0;

  ${MobileMediaQuery} {
    width: ${BUTTON_RADIUS_MOBILE};
    min-width: ${BUTTON_RADIUS_MOBILE};
    height: ${BUTTON_RADIUS_MOBILE};
    min-height: ${BUTTON_RADIUS_MOBILE};
  }
`

const Video = styled.video`
  border-radius: 24px;
  ${MobileMediaQuery} {
    border-radius: 0px;
  }
`

export const CardVideoWidget = ({
  widget: { videoUrl, videoPlaceholder },
}: IWidgetProps<WidgetVideo>) => {
  const videoRef = React.createRef<HTMLVideoElement>()
  const [isPlaying, setIsPlaying] = React.useState(false)
  const [isVideoStarted, setIsVideoStarted] = React.useState(false)

  const toggleVideoPlay = () => {
    if (isPlaying) {
      videoRef.current?.pause()
    } else {
      videoRef.current?.play()
      setIsVideoStarted(true)
    }
  }

  if (!videoUrl) return null

  return (
    <Wrapper variant="white" isFullWidthMobile>
      <VideoRelativeWrapper>
        <AbsoluteWrapper>
          <PlaceholderWrapper isVisible={!isVideoStarted}>
            {videoPlaceholder && (
              <ContentfulImage
                image={videoPlaceholder}
                style={{ width: "100%", height: "100%", borderRadius: 24 }}
              />
            )}
          </PlaceholderWrapper>
          <Video
            onPlaying={() => {
              setIsPlaying(true)
            }}
            onPause={() => {
              setIsPlaying(false)
            }}
            onEnded={() => {
              setIsPlaying(false)
              setIsVideoStarted(false)
            }}
            width="100%"
            ref={videoRef}
            disablePictureInPicture
            controlsList="nodownload"
            playsInline
            src={videoUrl}
          />
          <PlayButtonWrapper
            onClick={() => {
              toggleVideoPlay()
            }}
          >
            <PlayButton
              isVisible={!isPlaying}
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation()
                toggleVideoPlay()
              }}
            >
              <PlayButtonIcon width="160" height="160" />
            </PlayButton>
          </PlayButtonWrapper>
        </AbsoluteWrapper>
      </VideoRelativeWrapper>
    </Wrapper>
  )
}

export const CardVideoFragment = graphql`
  fragment CardVideoWidget on ContentfulVideoWidget {
    videoUrl
    videoPlaceholder {
      file {
        details {
          image {
            width
          }
        }
      }
      gatsbyImageData(placeholder: TRACED_SVG, backgroundColor: "#000000")
      svg {
        content
      }
      fluid {
        base64
        tracedSVG
        aspectRatio
        src
        srcSet
        srcWebp
        srcSetWebp
        sizes
      }
    }
  }
`
