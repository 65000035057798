import styled from "@emotion/styled"
import React from "react"
import colors from "../styles/colors"
import { MobileMediaQuery } from "../styles/constants"
import Card from "./card"
import { BSH, Husqvarna, Jabra } from "./cards/cardPartners"
import Link from "./link"
import Typography from "./typography"

const Top = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 2.5rem;

  ${MobileMediaQuery} {
    flex-direction: column;
    align-items: flex-start;
  }
`

const Bottom = styled.div`
  display: flex;

  ${MobileMediaQuery} {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
`
const PreviewCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  background-color: ${colors.darkgrey};
  color: ${colors.white};
  border-radius: 4px;
  padding: 30px;
  height: 260px;
  max-width: 325px;

  margin-left: ${p => (p.hasMargin ? "33px" : 0)};

  ${MobileMediaQuery} {
    margin-left: 0;
    margin-top: ${p => (p.hasMargin ? "33px" : 0)};
  }
`

const LinkToAll = styled(Link)`
  text-decoration: none;
  color: ${colors.darkgrey};

  ${MobileMediaQuery} {
    margin-top: 1rem;
  }
`
const LinkTo = styled(Link)`
  text-decoration: none;
  color: ${colors.darkgrey};
  margin-top: 1rem;
`

const BottomPreview = styled.div`
  display: flex;
  flex-direction: column;
`

const stories = [
  {
    logo: <Jabra style={{ height: 24 }} />,
    text:
      "Jabra uses Mavenoid to reduce time spent on warranty claims by more than 40%",
    linkTo: "/customers/jabra",
  },
  {
    logo: <BSH style={{ height: 37 }} />,
    text:
      "Troubleshooting with Mavenoid leads to 70% more customers helping themselves",
    linkTo: "/customers/bsh",
  },
  {
    logo: <Husqvarna style={{ height: 27 }} />,
    text:
      "Mavenoid enables instant, personalized support to customers on nine markets",
    linkTo: "/customers/husqvarna",
  },
]

export const SuccessStoriesPreview = () => {
  return (
    <Card variant="white" noMinHeight>
      <Top>
        <Typography variant="h4">More stories from our customers</Typography>
        <LinkToAll to="/customers">View all</LinkToAll>
      </Top>
      <Bottom>
        {stories.map((story, i) => (
          <PreviewCard hasMargin={i !== 0}>
            {story.logo}
            <BottomPreview>
              {story.text}
              <LinkTo to={story.linkTo}>
                <svg
                  width="26"
                  height="12"
                  viewBox="0 0 26 12"
                  stroke={colors.white}
                >
                  <path
                    d="M1 6.02765L24.3667 6.02765M24.3667 6.02765L20.339 2M24.3667 6.02765L20.339 10.0553"
                    stroke-width="1.5"
                    stroke-linecap="square"
                  />
                </svg>
              </LinkTo>
            </BottomPreview>
          </PreviewCard>
        ))}
      </Bottom>
    </Card>
  )
}
